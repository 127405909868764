import { Action } from 'redux';

import { REMIND_PASSWORD_TYPE } from '../../constants/action_types';
import {
  fetchRemindPasswordByUserId,
  IRemindPasswordByUserIdResponse204,
  IRemindPasswordByUserIdResponse400,
} from '../../repositories/users/v1/remind-password-by-user-id';
import { EAsyncActionStatus } from '../../types/asyncAction';
import { TThunkAction } from '../../types/redux/thunk';

export type TRemindPasswordType = 'remindPassword/status_changed';

export interface IRemindPasswordFailed extends Action<TRemindPasswordType> {
  type: TRemindPasswordType;
  status: EAsyncActionStatus.Failed;
  error: string;
}

export interface IRemindPassword extends Action<TRemindPasswordType> {
  type: TRemindPasswordType;
  status: EAsyncActionStatus.Loading | EAsyncActionStatus.Succeed;
}

export type TRemindPassword = IRemindPassword | IRemindPasswordFailed;

export function remindPassword(): TThunkAction<Promise<void>> {
  return async (dispatch, getState, { httpApi, logger }) => {
    const {
      resetPassword: { userId },
    } = getState();

    if (!userId) {
      return;
    }

    dispatch<TRemindPassword>({
      type: REMIND_PASSWORD_TYPE,
      status: EAsyncActionStatus.Loading,
    });

    try {
      const res = await fetchRemindPasswordByUserId<
        IRemindPasswordByUserIdResponse204,
        IRemindPasswordByUserIdResponse400
      >({
        httpApi,
        parameters: {
          userId,
        },
        config: {
          requestConfig: {
            headers: [['Content-Type', 'application/json']],
          },
        },
      });

      if (res.statusCode === 204) {
        dispatch<TRemindPassword>({
          type: REMIND_PASSWORD_TYPE,
          status: EAsyncActionStatus.Succeed,
        });
      } else {
        dispatch<TRemindPassword>({
          type: REMIND_PASSWORD_TYPE,
          status: EAsyncActionStatus.Failed,
          error: res.response.message,
        });
      }
    } catch (e) {
      logger.error(e);
      dispatch<TRemindPassword>({
        type: REMIND_PASSWORD_TYPE,
        status: EAsyncActionStatus.Failed,
        error: 'Произошла ошибка, попробуйте еще раз',
      });
    }
  };
}
