import { ValidationError } from '@cian/peperrors/shared';
import { pathOr } from 'ramda';

import { SocialAuthWindowCallbackError } from '../../../errors';
import { IApplicationContext } from '../../../types/applicationContext';
import { ISocialAuthResponse, ISocialLoginSuccessData } from '../../../types/socialLogin';

export function successHandler(context: IApplicationContext, socialAuthResponse: ISocialAuthResponse) {
  if (!window.opener || !window.opener.socialLogOnStart) {
    context.logger.error(
      new SocialAuthWindowCallbackError({
        message: 'Не удалось авторизоваться через соц сеть, отсутствует callback "socialLogOnStart"',
        details: {
          socialType: socialAuthResponse.socialType,
        },
        domain: 'shared.containers.SocialLogin.utils.successHandler',
      }),
    );

    return;
  }

  const successData = pathOr<ISocialLoginSuccessData | null>(
    null,
    ['socialApiResponse', 'successData'],
    socialAuthResponse,
  );

  if (!successData) {
    context.logger.error(
      new ValidationError({
        message: 'Отсутствуют данные для успешной авторизации через соц сеть',
        details: {
          socialType: socialAuthResponse.socialType,
        },
        domain: 'shared.containers.SocialLogin.utils.successHandler',
      }),
    );

    return;
  }

  context.logger.debug('Получен статус success, вызывается обработчик socialLogOnStart', {
    'successData.socialLoginId': successData.socialLoginId,
    'successData.email': successData.email,
    'successData.token': successData.token,
    'successData.userCreated': successData.userCreated,
  });

  window.opener.socialLogOnStart(
    successData.socialLoginId,
    successData.email,
    successData.token,
    successData.userCreated,
  );

  window.close();
}
