import { EAsyncActionStatus } from '../../types/asyncAction';
import { ISocialLoginState } from '../../types/socialLogin';

export const defaultState: ISocialLoginState = {
  socialType: null,
  authSocialLogin: {
    data: null,
    status: EAsyncActionStatus.Initial,
  },
};

export function socialLoginReducer(state: ISocialLoginState = defaultState) {
  return state;
}
