import { ValidationError } from '@cian/peperrors/shared';
import { pathOr } from 'ramda';

import { SocialAuthWindowCallbackError } from '../../../errors';
import { IApplicationContext } from '../../../types/applicationContext';
import { ISocialAuthResponse, ISocialUserData } from '../../../types/socialLogin';

export function userNotFoundHandler(context: IApplicationContext, socialAuthResponse: ISocialAuthResponse) {
  if (!window.opener || !window.opener._socialResponse) {
    context.logger.error(
      new SocialAuthWindowCallbackError({
        message: 'Не удалось авторизоваться через соц сеть, отсутствует callback "_socialResponse"',
        details: {
          socialType: socialAuthResponse.socialType,
        },
        domain: 'shared.containers.SocialLogin.utils.userNotFoundHandler',
      }),
    );

    return;
  }

  const userData = pathOr<ISocialUserData | null>(null, ['socialApiResponse', 'userData'], socialAuthResponse);

  if (!userData) {
    context.logger.error(
      new ValidationError({
        message: 'Отсутствуют данные о пользователе, необходимые для авторизации по соц сети',
        details: {
          socialType: socialAuthResponse.socialType,
        },
        domain: 'shared.containers.SocialLogin.utils.userNotFoundHandler',
      }),
    );

    return;
  }

  context.logger.debug('Получен статус userNotFound, вызывается обработчик _socialResponse', {
    'userData.socialType': userData.socialType,
    'userData.socialLoginId': userData.socialLoginId,
    'userData.email': userData.email,
    'userData.phone': userData.phone,
  });

  window.opener._socialResponse('userNotFound', {
    socialType: userData.socialType,
    socialLoginId: userData.socialLoginId,
    email: userData.email,
    phone: userData.phone,
  });

  window.close();
}
