import { Action } from 'redux';

import { PAGE_SET_PAGE_TYPE } from '../../constants/action_types';
import { EPageType } from '../../types/page';
import { TThunkAction } from '../../types/redux/thunk';

export type TSetPageType = 'page/setPage';

export interface ISetPageType extends Action<TSetPageType> {
  type: TSetPageType;
  pageType: EPageType;
}

interface ISetPageParameters {
  type: EPageType;
  login?: string;
}

export function setPage(parameters: ISetPageParameters): TThunkAction {
  return (dispatch, getState) => {
    const { page } = getState();
    const { returnUrl } = page;

    switch (parameters.type) {
      case EPageType.Authorization:
        if (window) {
          const nextUrl = new URL(`${window.location.origin}/authenticate/`);

          if (returnUrl) {
            nextUrl.searchParams.set('returnUrl', returnUrl);
          }

          if (parameters.login) {
            nextUrl.searchParams.set('login', parameters.login);
          }

          window.location.assign(nextUrl);
        }
        break;

      case EPageType.Registration:
        if (window) {
          const nextUrl = new URL(`${window.location.origin}/registration/`);

          if (returnUrl) {
            nextUrl.searchParams.set('returnUrl', returnUrl);
          }

          if (parameters.login) {
            nextUrl.searchParams.set('login', parameters.login);
          }

          window.location.assign(nextUrl);
        }
        break;

      default:
        throw new Error('Unrecognized pageType');
    }

    dispatch<ISetPageType>({
      type: PAGE_SET_PAGE_TYPE,
      pageType: parameters.type,
    });
  };
}
