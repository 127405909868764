import { ErrorLogComponent } from '@cian/error-log-component';
import { NoSsr } from '@cian/react-utils';
import { uaToDeviceType, UiKitContextProvider } from '@cian/ui-kit';
import * as React from 'react';
import { Provider } from 'react-redux';

import './App.css';
import { IApplicationContext } from '../../types/applicationContext';
import { IReduxStore } from '../../types/redux';
import { ApplicationContext } from '../../utils/applicationContext';
import { AuthenticationContainer } from '../Authentication';
import { NewPasswordContainer } from '../NewPassword';
import { SocialLoginContainer } from '../SocialLogin';

export interface IApplicationProps {
  context: IApplicationContext;
  reduxStore: IReduxStore;
}

export const Application: React.FC<IApplicationProps> = ({ reduxStore, context }) => {
  const {
    logger,
    custom: { userAgent },
  } = context;

  return (
    <ErrorLogComponent logger={logger}>
      <ApplicationContext.Provider value={context}>
        <UiKitContextProvider deviceType={uaToDeviceType(userAgent || undefined)}>
          <Provider store={reduxStore}>
            <NoSsr>
              <AuthenticationContainer />
            </NoSsr>
            <NewPasswordContainer />
            <SocialLoginContainer />
          </Provider>
        </UiKitContextProvider>
      </ApplicationContext.Provider>
    </ErrorLogComponent>
  );
};
