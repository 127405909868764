import { PasswordField, FormFieldGroupLayout } from '@cian/authentication-widget';
import { Button } from '@cian/ui-kit';
import { useField, useFormikContext } from 'formik';
import * as React from 'react';

import * as styles from './ResetPasswordForm.css';

export interface IResetPasswordFormProps {
  /** email нужен для корректной работы менеджеров паролей */
  email: string;
  /** флаг асинхронной отправки формы, для блокировки контролов */
  isSubmitting: boolean;
  /** ошибка при сохранении формы */
  asyncError?: string;
  /** обработчик отправки формы */
  handleSubmit(): void;
  /** ссылка на первый инпут с паролем */
  passwordRef?: React.Ref<HTMLInputElement>;
  /** ссылка на второй инпут с паролем */
  confirmPasswordRef?: React.Ref<HTMLInputElement>;
  /** видимость паролей */
  isPasswordsVisible: boolean;
  /** обработчик переключения видимости паролей */
  togglePasswordsVisibility(): void;
}

export const ResetPasswordForm: React.FC<IResetPasswordFormProps> = props => {
  const handlePreventSubmit = (e: React.FormEvent) => {
    e.preventDefault();
  };

  const { setStatus } = useFormikContext<{ password: string; confirmPassword: string }>();

  const [passwordField, passwordFieldMeta] = useField<string>('password');
  const [confirmPasswordField, confirmPasswordFieldMeta] = useField<string>('confirmPassword');

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStatus('changed');
    passwordField.onChange(e);
  };

  const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStatus('changed');
    confirmPasswordField.onChange(e);
  };

  const handleSubmitOnEnter = (e: React.KeyboardEvent) => {
    if (e.keyCode === 13) {
      props.handleSubmit();
    }
  };

  return (
    <form onSubmit={handlePreventSubmit} data-testid="ResetPasswordForm">
      <FormFieldGroupLayout
        content={
          <>
            <input className={styles['hidden-input']} value={props.email} name="login" />
            <PasswordField
              id="new-password"
              autoComplete="new-password"
              {...passwordField}
              onChange={handlePasswordChange}
              ref={props.passwordRef}
              onKeyDown={handleSubmitOnEnter}
              disabled={props.isSubmitting}
              invalid={(passwordFieldMeta.touched && Boolean(passwordFieldMeta.error)) || Boolean(props.asyncError)}
              placeholder="Новый пароль"
              passwordShown={props.isPasswordsVisible}
              toggleVisibility={props.togglePasswordsVisibility}
            />
            <PasswordField
              id="confirm-password"
              autoComplete="new-password"
              {...confirmPasswordField}
              onChange={handleConfirmPasswordChange}
              ref={props.confirmPasswordRef}
              onKeyDown={handleSubmitOnEnter}
              disabled={props.isSubmitting}
              invalid={
                (confirmPasswordFieldMeta.touched && Boolean(confirmPasswordFieldMeta.error)) ||
                Boolean(props.asyncError)
              }
              errorMessage={(confirmPasswordFieldMeta.touched && confirmPasswordFieldMeta.error) || props.asyncError}
              placeholder="Повторите пароль"
              passwordShown={props.isPasswordsVisible}
              toggleVisibility={props.togglePasswordsVisibility}
            />
          </>
        }
        buttons={
          <Button
            data-name="ContinueAuthBtn"
            theme="fill_primary"
            type="submit"
            fullWidth
            loading={props.isSubmitting}
            onClick={props.handleSubmit}
          >
            Сохранить пароль
          </Button>
        }
      />
    </form>
  );
};
