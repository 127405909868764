import { PAGE_SET_PAGE_TYPE } from '../../constants/action_types';
import { PAGE_TYPE_TO_CONTAINER } from '../../constants/pageTypeToContainer';
import { IPageState, EPageContainer, EPageType } from '../../types/page';
import { TReduxActions } from '../../types/redux';

const defaultState: IPageState = {
  returnUrl: null,
  pageType: EPageType.Authorization,
  pageContainer: EPageContainer.Authentication,
};

export function pageReducer(state: IPageState = defaultState, action: TReduxActions) {
  switch (action.type) {
    case PAGE_SET_PAGE_TYPE:
      return {
        ...state,
        pageType: action.pageType,
        pageContainer: PAGE_TYPE_TO_CONTAINER[action.pageType],
      };
  }

  return state;
}
