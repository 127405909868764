import { ContentLayout, Help, SubtitleLayout, Title, View } from '@cian/authentication-widget';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { resetPassword } from '../../actions/resetPassword';
import { PreFormattedText } from '../../components/PreFormattedText';
import { selectPageContainer } from '../../selectors/page';
import { selectResetPassword } from '../../selectors/resetPassword';
import { EPageContainer } from '../../types/page';
import { TThunkDispatch } from '../../types/redux/thunk';
import { useApplicationContext } from '../../utils/applicationContext';
import { trackRecoveryNeedHelpClick } from '../../utils/tracking/resetPassword';
import { ResetPasswordFormContainer } from '../ResetPasswordFormContainer';

const SUBTITLE = 'Пароль должен содержать не менее 8 символов. Нельзя использовать старый пароль.';

export const ResetPasswordContainer: React.VFC = () => {
  const { config } = useApplicationContext();
  const dispatch = useDispatch<TThunkDispatch>();
  const passwordValidationRegex = config.get<string>('authentication.password.regex') || '';
  const container = useSelector(selectPageContainer);
  const { status: resetPasswordStatus, error: resetPasswordError, data } = useSelector(selectResetPassword);
  const { email, token } = data;
  const hasValidResetPasswordData = !!email && !!token;
  const handleResetPassword = React.useCallback(
    (password: string) => {
      dispatch(resetPassword(password));
    },
    [dispatch],
  );

  if (container !== EPageContainer.NewPassword || !hasValidResetPasswordData) {
    return null;
  }

  return (
    <View>
      <Title>Восстановление пароля</Title>
      <SubtitleLayout>
        <ContentLayout>
          <PreFormattedText value={SUBTITLE} />
        </ContentLayout>
      </SubtitleLayout>
      <ResetPasswordFormContainer
        email={email}
        regex={passwordValidationRegex}
        handleSubmit={handleResetPassword}
        status={resetPasswordStatus}
        error={resetPasswordError}
      />
      <Help onClick={trackRecoveryNeedHelpClick} />
    </View>
  );
};

ResetPasswordContainer.displayName = 'ResetPasswordContainer';
