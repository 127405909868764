import { ValidationError } from '@cian/peperrors/shared';
import { pathOr } from 'ramda';

import { SocialAuthWindowCallbackError } from '../../../errors';
import { IApplicationContext } from '../../../types/applicationContext';
import { ISocialAuthResponse, ISocialLoginNeedMergeData } from '../../../types/socialLogin';

export function needMergeHandler(context: IApplicationContext, socialAuthResponse: ISocialAuthResponse) {
  if (!window.opener || !window.opener.showNeedEmailPopup) {
    context.logger.error(
      new SocialAuthWindowCallbackError({
        message: 'Не удалось авторизоваться через соц сеть, отсутствует callback "showNeedEmailPopup"',
        details: {
          socialType: socialAuthResponse.socialType,
        },
        domain: 'shared.containers.SocialLogin.utils.needMergeHandler',
      }),
    );

    return;
  }

  const needMergeData = pathOr<ISocialLoginNeedMergeData | null>(
    null,
    ['socialApiResponse', 'needMergeData'],
    socialAuthResponse,
  );

  if (!needMergeData) {
    context.logger.error(
      new ValidationError({
        message: 'Отсутствуют данные в поле "needMergeData", необходимые для успешной авторизации через соц сеть',
        details: {
          socialType: socialAuthResponse.socialType,
        },
        domain: 'shared.containers.SocialLogin.utils.needMergeHandler',
      }),
    );

    return;
  }

  context.logger.debug('Получен статус needMerge, вызывается обработчик showNeedEmailPopup', {
    socialType: socialAuthResponse.socialType,
    'needMergeData.socialLoginId': needMergeData.socialLoginId,
    'needMergeData.mergeEmail': needMergeData.mergeEmail,
  });

  window.opener.showNeedMergeEmailPopup(
    needMergeData.mergeEmail,
    needMergeData.socialLoginId,
    socialAuthResponse.socialType,
  );

  window.close();
}
