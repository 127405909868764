import {
  Provider,
  Authentication as AuthenticationWidget,
  IAuthenticationContext,
  EEventType,
  EActiveTab,
  ESentEmailType,
} from '@cian/authentication-widget';
import * as React from 'react';

import * as style from './Authentication.css';
import { EPageType } from '../../types/page';

export interface IAuthenticationProps {
  returnUrl: string | null;
  authenticationContext: IAuthenticationContext;
  disabledSocials: string[];
  setPage(type: EPageType): void;
}

export class Authentication extends React.PureComponent<IAuthenticationProps> {
  public render() {
    const { authenticationContext } = this.props;

    return (
      <div className={style['container']}>
        <Provider context={authenticationContext}>
          <div className={style['wrapper']}>{this.renderAuthWidget()}</div>
        </Provider>
      </div>
    );
  }

  public renderAuthWidget() {
    const { disabledSocials } = this.props;

    return <AuthenticationWidget disabledSocials={disabledSocials} frameless />;
  }

  public componentDidMount() {
    this.props.authenticationContext.on(EEventType.TabChanged, this.handleTabChanged);
    this.props.authenticationContext.on(EEventType.AuthenticateSucceed, this.authenticationSucceed);
    this.props.authenticationContext.on(EEventType.RegisterSucceed, this.handleRegisterSucceed);
    this.props.authenticationContext.on(EEventType.EmailSentClosed, this.handleEmailSentClosed);
    this.props.authenticationContext.on(EEventType.SocialAuthenticateSucceed, this.handleSocialAuthenticateSucceed);
  }

  public componentWillUnmount() {
    this.props.authenticationContext.off(EEventType.TabChanged, this.handleTabChanged);
    this.props.authenticationContext.off(EEventType.AuthenticateSucceed, this.authenticationSucceed);
    this.props.authenticationContext.off(EEventType.RegisterSucceed, this.handleRegisterSucceed);
    this.props.authenticationContext.off(EEventType.EmailSentClosed, this.handleEmailSentClosed);
    this.props.authenticationContext.off(EEventType.SocialAuthenticateSucceed, this.handleSocialAuthenticateSucceed);
  }

  private handleTabChanged = ({ tab }: { tab: EActiveTab }) => {
    const { setPage } = this.props;

    switch (tab) {
      case EActiveTab.Authentication: {
        setPage(EPageType.Authorization);
        break;
      }

      case EActiveTab.Registration: {
        setPage(EPageType.Registration);
        break;
      }
    }
  };

  private authenticationSucceed = () => {
    const { returnUrl } = this.props;
    window.location.replace(returnUrl || '/');
  };

  private handleRegisterSucceed = (event?: { senderType: string }) => {
    if (event && event.senderType) {
      this.authenticationSucceed();
    }
  };

  private handleEmailSentClosed = ({ type }: { type: ESentEmailType }) => {
    if (type === ESentEmailType.Registration) {
      this.authenticationSucceed();
    }
  };

  private handleSocialAuthenticateSucceed = ({ isNewUser }: { isNewUser: boolean }) => {
    if (!isNewUser) {
      this.authenticationSucceed();
    }
  };
}
