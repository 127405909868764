import { ContentLayout } from '@cian/authentication-widget';
import { Formik, FormikBag } from 'formik';
import * as React from 'react';
import * as Yup from 'yup';

import { ResetPasswordForm } from '../../components/ResetPasswordForm';
import { EAsyncActionStatus } from '../../types/asyncAction';
import { trackResetPasswordSaveClick } from '../../utils/tracking/resetPassword';

export interface IFormValues {
  password: string;
  confirmPassword: string;
}

export interface IResetPasswordFormContainerProps {
  email: string;
  status: EAsyncActionStatus;
  regex?: string;
  error?: string;
  handleSubmit(password: string): void;
}

export const ResetPasswordFormContainer: React.FC<IResetPasswordFormContainerProps> = ({
  email,
  regex,
  status,
  error,
  handleSubmit,
}) => {
  const [submitting, setSubmitting] = React.useState(status === EAsyncActionStatus.Loading);
  const [isPasswordsVisible, setPasswordsVisible] = React.useState(false);
  const passwordRegex = React.useMemo(() => (regex ? new RegExp(regex) : null), [regex]);

  const handleSubmitForm = (values: IFormValues, actions: FormikBag<{}, IFormValues>) => {
    if (!submitting) {
      trackResetPasswordSaveClick();
      setPasswordsVisible(false);
      actions.setStatus(undefined);
      handleSubmit(values.password);
    }
  };

  React.useEffect(() => {
    setSubmitting(status === EAsyncActionStatus.Loading);
  }, [status]);

  return (
    <ContentLayout>
      <Formik
        onSubmit={handleSubmitForm}
        initialValues={{
          password: '',
          confirmPassword: '',
        }}
        validationSchema={Yup.object({
          password: Yup.string()
            .required('Введите пароль')
            .test(
              'passwordMatch',
              'Пароль должен удовлетворять условию',
              value => (passwordRegex && passwordRegex.test(value as string)) || !passwordRegex,
            ),
          confirmPassword: Yup.string().when('password', (password: string, schema: Yup.StringSchema) =>
            password
              ? schema.test('equal', 'Пароли не совпадают', confirmPassword => confirmPassword === password)
              : schema,
          ),
        })}
      >
        {({ handleSubmit, status }) => (
          <ResetPasswordForm
            email={email}
            isSubmitting={submitting}
            asyncError={(!submitting && status === undefined && error) || ''}
            handleSubmit={handleSubmit}
            isPasswordsVisible={isPasswordsVisible}
            togglePasswordsVisibility={() => setPasswordsVisible(!isPasswordsVisible)}
          />
        )}
      </Formik>
    </ContentLayout>
  );
};

ResetPasswordFormContainer.displayName = 'ResetPasswordFormContainer';
