import { GenericError, IErrorDetails } from '@cian/peperrors/shared';

export class SocialAuthWindowCallbackError extends GenericError {
  public constructor(configuration: { domain: string; message: string; details?: IErrorDetails }) {
    super({
      ...configuration,
      type: 'SocialAuthWindowCallbackError',
    }) /* istanbul ignore next */;

    Object.setPrototypeOf(this, SocialAuthWindowCallbackError.prototype);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, SocialAuthWindowCallbackError);
    } else {
      this.stack = new Error().stack;
    }
  }
}
