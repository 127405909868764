import { ValidationError } from '@cian/peperrors/shared';
import { pathOr } from 'ramda';

import { SocialAuthWindowCallbackError } from '../../../errors';
import { IApplicationContext } from '../../../types/applicationContext';
import { ISocialAuthResponse, ISocialLoginNeedEmailData } from '../../../types/socialLogin';

export function needEmailHandler(context: IApplicationContext, socialAuthResponse: ISocialAuthResponse) {
  if (!window.opener || !window.opener.showNeedEmailPopup) {
    context.logger.error(
      new SocialAuthWindowCallbackError({
        message: 'Не удалось авторизоваться через соц сеть, отсутствует callback "showNeedEmailPopup"',
        details: {
          socialType: socialAuthResponse.socialType,
        },
        domain: 'shared.containers.SocialLogin.utils.needEmailHandler',
      }),
    );

    return;
  }

  const needEmailData = pathOr<ISocialLoginNeedEmailData | null>(
    null,
    ['socialApiResponse', 'needEmailData'],
    socialAuthResponse,
  );

  if (!needEmailData) {
    context.logger.error(
      new ValidationError({
        message: 'Отсутствуют данные в поле "needEmailData", необходимые для успешной авторизации через соц сеть',
        details: {
          socialType: socialAuthResponse.socialType,
        },
        domain: 'shared.containers.SocialLogin.utils.needEmailHandler',
      }),
    );

    return;
  }

  context.logger.debug('Получен статус needEmail, вызывается обработчик showNeedEmailPopup', {
    socialType: socialAuthResponse.socialType,
    'needEmailData.socialLoginId': needEmailData.socialLoginId,
  });

  window.opener.showNeedEmailPopup(socialAuthResponse.socialType, needEmailData.socialLoginId);

  window.close();
}
