import { pathOr } from 'ramda';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { errorHandler } from './utils/errorHandler';
import { fatalErrorHandler } from './utils/fatalErrorHandler';
import { successHandler } from './utils/successHandler';
import { userNotFoundHandler } from './utils/userNotFoundHandler';
import { selectAuthSocialLogin } from '../../selectors/socialLogin';
import { EAsyncActionStatus } from '../../types/asyncAction';
import { EStatus } from '../../types/socialLogin';
import { useApplicationContext } from '../../utils/applicationContext';

export const SocialLoginContainer: React.FC = () => {
  const context = useApplicationContext();
  const authSocialLoginState = useSelector(selectAuthSocialLogin);

  React.useEffect(() => {
    if (authSocialLoginState.status !== EAsyncActionStatus.Succeed) {
      return;
    }

    const socialAuthResponse = authSocialLoginState.data.socialAuthResponse;
    const socialAuthResponseCode = authSocialLoginState.data.status;
    const socialApiResponseCode = pathOr<EStatus | null>(null, ['socialApiResponse', 'status'], socialAuthResponse);

    context.logger.debug('Получены статусы для авторизации по соц сети', {
      'socialLoginState.status': socialAuthResponseCode,
      'socialLoginState.socialAuthResponse.socialApiResponse.status': socialApiResponseCode,
    });

    if (socialAuthResponseCode === 'user_not_found') {
      userNotFoundHandler(context, socialAuthResponse);
    }

    /**
     * Deprecated below this line
     */
    if (socialAuthResponseCode === 'success') {
      successHandler(context, socialAuthResponse);
    }

    if (socialAuthResponseCode === 'error') {
      errorHandler(context, socialAuthResponse);
    }

    // TODO возможно проверка socialApiResponseCode === 'fatal_error' лишняя, вероятно нужно выпилить
    if ((socialApiResponseCode as string) === 'fatal_error' || socialAuthResponseCode === 'fatal_error') {
      fatalErrorHandler(context, socialAuthResponse);
    }

    if (socialAuthResponseCode === 'AuthDeniedByUser') {
      window.close();
    }
  }, []);

  if (authSocialLoginState.status === EAsyncActionStatus.Failed) {
    return <>{authSocialLoginState.message}</>;
  }

  return null;
};
