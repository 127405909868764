import { applyMiddleware, CombinedState, combineReducers, compose, createStore as _createStore } from 'redux';
import thunk, { ThunkDispatch } from 'redux-thunk';

import { experimentsReducer } from '../../reducers/experiments';
import { pageReducer } from '../../reducers/page';
import { remindPasswordReducer } from '../../reducers/remindPassword';
import { resetPasswordReducer } from '../../reducers/resetPassword';
import { socialLoginReducer } from '../../reducers/socialLogin';
import { IApplicationContext } from '../../types/applicationContext';
import { IApplicationState, IApplicationStateInitial, TReduxActions } from '../../types/redux';

interface IThunkExt {
  dispatch: ThunkDispatch<IApplicationState, IApplicationContext, TReduxActions>;
}

/* istanbul ignore next */
const composeEnhancers = (() => {
  if (process.env.NODE_ENV === 'development' && process.env.IS_BROWSER_BUILD) {
    const { __REDUX_DEVTOOLS_EXTENSION_COMPOSE__ } = window;

    if (__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
      return __REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        name: `authentication-frontend: ${document.title}`,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any);
    }
  }

  return compose;
})();

/**
 * Хелпер для создания Redux стора
 * @param initialState IApplicationState - предзагруженное состояние
 * @param context
 */
export function createReduxStore(initialState: IApplicationStateInitial, context: IApplicationContext) {
  const reducers = combineReducers<IApplicationState, TReduxActions>({
    page: pageReducer,
    remindPassword: remindPasswordReducer,
    resetPassword: resetPasswordReducer,
    socialLogin: socialLoginReducer,
    experiments: experimentsReducer,
  });
  const middlewares = [thunk.withExtraArgument(context)];

  const storeEnhancers = composeEnhancers(applyMiddleware(...middlewares));

  return _createStore<CombinedState<IApplicationState>, TReduxActions, IThunkExt, {}>(
    reducers,
    initialState,
    storeEnhancers,
  );
}
