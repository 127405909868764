import { Action } from 'redux';

import { SET_RESET_PASSWORD_DATA } from '../../constants/action_types';
import { IResetPasswordData } from '../../types/resetPassword';

export type TSetResetPasswordDataType = 'resetPassword/set_data';

export interface ISetResetPasswordData extends Action<TSetResetPasswordDataType> {
  type: TSetResetPasswordDataType;
  data: IResetPasswordData;
}

export type TResetPasswordData = ISetResetPasswordData;

export function setResetPasswordData(data: IResetPasswordData): TResetPasswordData {
  return {
    type: SET_RESET_PASSWORD_DATA,
    data,
  };
}
