import { ContentLayout, ESubtitleOffset, Help, SubtitleLayout, Title, View } from '@cian/authentication-widget';
import { Button } from '@cian/ui-kit';
import { compose } from 'ramda';
import * as React from 'react';
import { connect } from 'react-redux';

import { remindPassword } from '../../actions/remindPassword';
import { ErrorMessage } from '../../components/ErrorMessage';
import { PreFormattedText } from '../../components/PreFormattedText';
import { EAsyncActionStatus } from '../../types/asyncAction';
import { EPageContainer } from '../../types/page';
import { IApplicationState } from '../../types/redux';
import { TThunkDispatch } from '../../types/redux/thunk';
import { withEmptyRendering } from '../../utils/hoc';
import {
  trackRecoveryLinkSendClick,
  trackRecoveryNeedHelpClick,
  trackResetPasswordGotItClick,
} from '../../utils/tracking/resetPassword';

export interface IResetPasswordLinkExpiredStateProps {
  container: EPageContainer;
  hasValidResetPasswordData: boolean;
  remindPasswordStatus: EAsyncActionStatus;
  remindPasswordError?: string;
}

export interface IResetPasswordLinkExpiredDispatchProps {
  remindPassword(): void;
}

export const ResetPasswordLinkExpired: React.FC<
  IResetPasswordLinkExpiredStateProps & IResetPasswordLinkExpiredDispatchProps
> = ({ remindPassword, remindPasswordError, remindPasswordStatus }) => {
  const [submitting, setSubmitting] = React.useState(remindPasswordStatus === EAsyncActionStatus.Loading);
  const [succeed, setSucceed] = React.useState(remindPasswordStatus === EAsyncActionStatus.Succeed);
  const [changed, setChanged] = React.useState(
    remindPasswordStatus === EAsyncActionStatus.Failed && Boolean(remindPasswordError),
  );
  const handleResendPassword = () => {
    if (changed) {
      setChanged(false);
    }
    if (!submitting) {
      trackRecoveryLinkSendClick();
      remindPassword();
    }
  };

  const handleGotItClick = () => {
    trackResetPasswordGotItClick();
    setTimeout(() => {
      window.location.href = '/';
    }, 300);
  };

  const button = succeed ? (
    <Button data-name="GotItBtn" theme="fill_primary" fullWidth onClick={handleGotItClick}>
      Хорошо
    </Button>
  ) : (
    <Button
      data-name="ResendEmailBtn"
      theme="fill_primary"
      fullWidth
      disabled={submitting}
      loading={submitting}
      onClick={handleResendPassword}
    >
      Отправить ссылку повторно
    </Button>
  );

  const subtitle = succeed
    ? 'Письмо с инструкцией по восстановлению пароля отправлено'
    : 'Ссылка для восстановления\nпароля устарела или пароль\nуже успешно сброшен';

  React.useEffect(() => {
    setSubmitting(remindPasswordStatus === EAsyncActionStatus.Loading);
    setSucceed(remindPasswordStatus === EAsyncActionStatus.Succeed);
    setChanged(remindPasswordStatus === EAsyncActionStatus.Failed);
  }, [remindPasswordStatus]);

  return (
    <View>
      <Title>Восстановление пароля</Title>
      <SubtitleLayout offset={ESubtitleOffset.XL}>
        <ContentLayout>
          <PreFormattedText value={subtitle} />
        </ContentLayout>
      </SubtitleLayout>
      {changed && remindPasswordError && <ErrorMessage>{remindPasswordError}</ErrorMessage>}
      <ContentLayout>{button}</ContentLayout>
      <Help onClick={trackRecoveryNeedHelpClick} />
    </View>
  );
};

export function mapStateToProps(state: IApplicationState): IResetPasswordLinkExpiredStateProps {
  const { resetPassword, remindPassword, page } = state;
  const { email, token } = resetPassword.data;

  return {
    container: page.pageContainer,
    remindPasswordStatus: remindPassword.status,
    remindPasswordError: remindPassword.error,
    hasValidResetPasswordData: Boolean(email) && Boolean(token),
  };
}

ResetPasswordLinkExpired.displayName = 'ResetPasswordLinkExpired';

export function mapDispatchToProps(dispatch: TThunkDispatch): IResetPasswordLinkExpiredDispatchProps {
  return {
    remindPassword() {
      dispatch(remindPassword());
    },
  };
}

export function shouldHideContainer(props: IResetPasswordLinkExpiredStateProps): boolean {
  return props.container !== EPageContainer.NewPassword || props.hasValidResetPasswordData;
}

export const ResetPasswordLinkExpiredContainer = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withEmptyRendering(shouldHideContainer),
)(ResetPasswordLinkExpired);
