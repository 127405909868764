import {
  createContext as createAuthenticationContext,
  IState as IAuthenticationState,
} from '@cian/authentication-widget';
import { getConfig } from '@cian/config/browser';
import { getHttpApi } from '@cian/http-api/browser';
import { getLogger } from '@cian/logger/browser';
import { getTelemetry } from '@cian/telemetry/browser';

import { IApplicationContext, IApplicationContextCustom } from '../../../shared/types/applicationContext';

export const createContext = (): IApplicationContext => {
  const config = getConfig();
  const logger = getLogger();
  const httpApi = getHttpApi();
  const telemetry = getTelemetry();
  const custom = config.getStrict<IApplicationContextCustom>('applicationContext.custom');
  const authenticationInitialState = config.getStrict<IAuthenticationState>('authentication.initialState');
  const authenticationContext = createAuthenticationContext(
    { logger, config, httpApi, subdomain: custom.subdomain },
    authenticationInitialState,
  );

  return {
    config,
    logger,
    httpApi,
    telemetry,
    custom,
    authenticationContext,
  };
};
