import { REMIND_PASSWORD_TYPE } from '../../constants/action_types';
import { EAsyncActionStatus } from '../../types/asyncAction';
import { TReduxActions } from '../../types/redux';
import { IRemindPasswordState } from '../../types/remindPassword';

export const defaultState: IRemindPasswordState = {
  status: EAsyncActionStatus.Initial,
};

export function remindPasswordReducer(state: IRemindPasswordState = defaultState, { type, ...payload }: TReduxActions) {
  if (type === REMIND_PASSWORD_TYPE) {
    return {
      ...state,
      ...payload,
    };
  }

  return state;
}
