import { createContext } from './createContext';
import { createApplicationState } from '../applicationState';
import { renderApplication } from '../renderApplication';

export function runApp() {
  const context = createContext();

  const store = createApplicationState(context);

  renderApplication({ context, store });
}
