import * as React from 'react';

import * as styles from './PreFormattedText.css';

export interface IPreFormattedTextProps {
  value: string;
}
export const PreFormattedText: React.FC<IPreFormattedTextProps> = props => {
  return <div className={styles['text']}>{props.value}</div>;
};
