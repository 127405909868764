import { Action } from 'redux';

import { setResetPasswordData } from './setResetPasswordData';
import { RESET_PASSWORD_TYPE } from '../../constants/action_types';
import {
  fetchResetPassword,
  IResetPasswordResponse204,
  IResetPasswordResponse400,
} from '../../repositories/users/v1/reset-password';
import { EAsyncActionStatus } from '../../types/asyncAction';
import { EPageType } from '../../types/page';
import { TThunkAction } from '../../types/redux/thunk';
import { setPage } from '../page';

export type TResetPasswordType = 'resetPassword/status_changed';

export interface IResetPasswordFailed extends Action<TResetPasswordType> {
  type: TResetPasswordType;
  status: EAsyncActionStatus.Failed;
  error: string;
}

export interface IResetPassword extends Action<TResetPasswordType> {
  type: TResetPasswordType;
  status: EAsyncActionStatus.Loading | EAsyncActionStatus.Succeed;
}

export type TResetPassword = IResetPassword | IResetPasswordFailed;

export function resetPassword(password: string): TThunkAction<Promise<void>> {
  return async (dispatch, getState, { httpApi, logger }) => {
    const {
      resetPassword: {
        data: { token, userId, email },
      },
    } = getState();

    if (!token || !userId) {
      return;
    }

    dispatch<TResetPassword>({
      type: RESET_PASSWORD_TYPE,
      status: EAsyncActionStatus.Loading,
    });

    try {
      const res = await fetchResetPassword<IResetPasswordResponse204, IResetPasswordResponse400>({
        httpApi,
        parameters: {
          userId,
          token,
          password,
          passwordRepeat: password,
        },
        config: {
          requestConfig: {
            headers: [['Content-Type', 'application/json']],
          },
        },
      });
      if (res.statusCode === 204) {
        dispatch<TResetPassword>({
          type: RESET_PASSWORD_TYPE,
          status: EAsyncActionStatus.Succeed,
        });

        // переключаем на страницу авторизации
        dispatch(setPage({ type: EPageType.Authorization, login: email || String(userId) }));
      } else if (
        res.statusCode === 400 &&
        res.response.errors.length &&
        res.response.errors.some(error => error.key === 'Token')
      ) {
        dispatch(setResetPasswordData({}));
      } else {
        dispatch<TResetPassword>({
          type: RESET_PASSWORD_TYPE,
          status: EAsyncActionStatus.Failed,
          error: res.response.message,
        });
      }
    } catch (e) {
      logger.error(e);
      dispatch<TResetPassword>({
        type: RESET_PASSWORD_TYPE,
        status: EAsyncActionStatus.Failed,
        error: 'Произошла ошибка, попробуйте еще раз',
      });
    }
  };
}
