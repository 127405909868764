import { SocialAuthWindowCallbackError } from '../../../errors';
import { IApplicationContext } from '../../../types/applicationContext';
import { ISocialAuthResponse } from '../../../types/socialLogin';

export function fatalErrorHandler(context: IApplicationContext, socialAuthResponse: ISocialAuthResponse) {
  if (!window.opener || !window.opener.showErrorSocialAuth) {
    context.logger.error(
      new SocialAuthWindowCallbackError({
        message:
          'Не отобразить состояние ошибки во время авторизации через соц сеть, отсутствует callback "showErrorSocialAuth"',
        details: {
          socialType: socialAuthResponse.socialType,
        },
        domain: 'shared.containers.SocialLogin.utils.fatalErrorHandler',
      }),
    );

    return;
  }

  context.logger.debug('Получен статус fatal_error, вызывается обработчик showErrorSocialAuth');

  window.opener.showErrorSocialAuth();

  window.close();
}
