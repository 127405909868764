import { ca } from '@cian/analytics';

/**
 * Клик по ссылке нужна помощь
 */
export function trackRecoveryNeedHelpClick() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'AuthBlock',
    action: 'Click',
    label: 'reсovery_password_cant_confirm',
  });
}

/**
 * Клик по кнопке "хорошо" после запроса ссылки на восстановление
 */
export function trackResetPasswordGotItClick() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'AuthBlock',
    action: 'Click',
    label: 'reset_password_got_it',
  });
}

/**
 * Клик по кнопке "Сохранить пароль" на форме восстановления
 */
export function trackResetPasswordSaveClick() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'AuthBlock',
    action: 'Click',
    label: 'reсovery_password_save',
  });
}

/**
 * Клик по кнопке "Отправить ссылку повторно"
 */
export function trackRecoveryLinkSendClick() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'AuthBlock',
    action: 'Click',
    label: 'link_send_again',
  });
}
