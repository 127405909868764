import { RESET_PASSWORD_TYPE, SET_RESET_PASSWORD_DATA } from '../../constants/action_types';
import { EAsyncActionStatus } from '../../types/asyncAction';
import { TReduxActions } from '../../types/redux';
import { IResetPasswordState } from '../../types/resetPassword';

export const defaultState: IResetPasswordState = {
  status: EAsyncActionStatus.Initial,
  data: {},
  userId: null,
};

export function resetPasswordReducer(state: IResetPasswordState = defaultState, { type, ...payload }: TReduxActions) {
  if (type === RESET_PASSWORD_TYPE || type === SET_RESET_PASSWORD_DATA) {
    return {
      ...state,
      ...payload,
    };
  }

  return state;
}
