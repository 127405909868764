import { pathOr } from 'ramda';

import { needEmailHandler } from './needEmailHandler';
import { needMergeHandler } from './needMergeHandler';
import { IApplicationContext } from '../../../types/applicationContext';
import { EStatus, ISocialAuthResponse } from '../../../types/socialLogin';

export function errorHandler(context: IApplicationContext, socialAuthResponse: ISocialAuthResponse) {
  const status = pathOr<EStatus | null>(null, ['socialApiResponse', 'status'], socialAuthResponse);
  switch (status) {
    case 'needEmail':
      needEmailHandler(context, socialAuthResponse);
      break;
    case 'needMerge':
      needMergeHandler(context, socialAuthResponse);
      break;
  }
}
