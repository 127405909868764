import * as React from 'react';

import { ResetPasswordContainer } from '../ResetPasswordContainer';
import { ResetPasswordLinkExpiredContainer } from '../ResetPasswordLinkExpiredContainer';

export const NewPasswordContainer: React.FC = () => {
  return (
    <>
      <ResetPasswordContainer />
      <ResetPasswordLinkExpiredContainer />
    </>
  );
};

NewPasswordContainer.displayName = 'NewPasswordContainer';
