/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TResetPasswordModel,
  IResetPasswordRequest,
  IMappers,
  IResetPasswordResponseError,
  TResetPasswordResponse,
} from './types';

const defaultConfig: TResetPasswordModel = {
  apiType: 'public',
  assertStatusCodes: [204, 400],
  method: 'POST',
  microserviceName: 'users',
  pathApi: '/v1/reset-password/',
  hostType: 'api',
} as TResetPasswordModel;

function createResetPasswordModel(parameters: IResetPasswordRequest): TResetPasswordModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IResetPasswordOptions<TResponse204, TResponse400> {
  httpApi: IHttpApi;
  parameters: IResetPasswordRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse204, TResponse400>;
}

async function fetchResetPassword<TResponse204, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IResetPasswordOptions<TResponse204, TResponse400>): Promise<TResponse204 | TResponse400 | TResetPasswordResponse> {
  const { response, statusCode } = await httpApi.fetch(createResetPasswordModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 204) {
      return mappers[204]();
    }

    if (statusCode === 400) {
      return mappers[400](response as IResetPasswordResponseError);
    }
  }

  return { response, statusCode } as TResetPasswordResponse;
}

export { defaultConfig, createResetPasswordModel, fetchResetPassword };
