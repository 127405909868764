import * as React from 'react';
import { useSelector } from 'react-redux';

import { SocialLoginContainer } from './SocialLogin';
import { selectPageContainer } from '../../selectors/page';
import { EPageContainer } from '../../types/page';

export const SocialLoginConditionalContainer: React.FC = () => {
  const container = useSelector(selectPageContainer);

  if (container !== EPageContainer.SocialLogin) {
    return null;
  }

  return <SocialLoginContainer />;
};
